import { Controller } from '@hotwired/stimulus'
import Resizer from '../../../lib/resizer'

export default class extends Controller {
  static targets = ['fragment', 'saveButton', 'form']
  static values = { formScope: String }

  connect () {
    this.fragmentTargets.forEach(fragment => {
      if (this.isEditable(fragment)) {
        this.addSetoutData(fragment)
      }
    })
  }

  save () {
    const form = this.formTarget

    this.fragmentTargets.forEach(fragment => {
      const clone = fragment.cloneNode(true)
      this.removeSetoutData(clone)

      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = `${this.formScope}[fragments][][content]`
      input.value = clone.outerHTML
      form.appendChild(input)
    })

    this.saveButtonTarget.disabled = true
    form.submit()
  }

  addSetoutData (fragment) {
    fragment.className = (fragment.getAttribute('data-splicer') === 'photo') ? 'setout__fragment' : 'setout__fragment px-24 py-16'

    fragment.setAttribute('data-controller', 'setout--fragment')
    fragment.setAttribute('data-setout--editor-target', 'fragment')
    fragment.removeAttribute('data-target')

    fragment.children[0].setAttribute('contenteditable', 'true')
    fragment.children[0].addEventListener('input', function () {
      Resizer.allFonts(0)
    }, false)
  }

  removeSetoutData (fragment) {
    fragment.style.background = ''
    fragment.classList.remove('setout__fragment')
    fragment.classList.remove('setout__fragment--active')

    fragment.removeAttribute('data-max-height')
    fragment.removeAttribute('data-controller')

    fragment.children[0].removeAttribute('data-original-font-size')
    fragment.children[0].removeAttribute('data-current-font-size')
    fragment.children[0].removeAttribute('contenteditable')

    if (fragment.getAttribute('data-splicer') !== 'photo') {
      fragment.removeAttribute('data-splicer')
    }
  }

  isEditable (fragment) {
    const text = fragment.dataset.text

    if (fragment.children[0].innerHTML === 'festalab.com.br'){
      return false
    } else if (text == null) {
      return true
    } else {
      return !(text.includes('logo_white.png') || text.includes('logo_black.png'))
    }
  }

  get formScope () {
    return this.formScopeValue || 'invitation'
  }
}
